



























































































import {Component, Prop, Vue} from "vue-property-decorator";
import UyapTakipAcXmlForm from "@/components/eicrapro/UyapTakipAcXmlForm.vue";
import UyapTakipAcTakipListe from "@/components/eicrapro/UyapTakipAcTakipListe.vue";

@Component({
    components: {UyapTakipAcTakipListe, UyapTakipAcXmlForm}
})
export default class TakipAc extends Vue {
    step: number = 1;

}
